
import { defineComponent, PropType } from "vue";
import Modal from "@/components/Modal.vue";

interface PortfolioItemData {
  id: String;
  title: String;
  modal_text: String;
  link: String;
  image: String;
  modal: Boolean;
  modalImage: String | undefined;
  tags: Array<string>;
  stack: Array<any> | undefined;
  hideTags: boolean;
}

export default defineComponent({
  name: "PortfolioItem",
  props: {
    item: {
      type: Object as PropType<PortfolioItemData>,
      required: true,
    }
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    getImage: function (image: String) {
      return require("../assets/" + image);
    },

    getBackgroundImage: function (imageMode: String, image: String) {
      if (imageMode !== 'full') return;

      return this.getImage(image);
    },

    getModalImage: function (item: PortfolioItemData) {
      const image =
        item.modalImage !== undefined ? item.modalImage : item.image;

      return require("../assets/" + image);
    },

    canRenderModal() {
      return (this.item as PortfolioItemData).modal;
    },

    determineLink() {
      const itemLink = (this.item as PortfolioItemData).link;
      if (itemLink !== "") {
        window.open(itemLink as string);
      } else if (this.canRenderModal()) {
        this.open = true;
      }
    },
  },
  components: {
    Modal,
  },
});
