<template>
  <div class="wrapper">
    <div class="item" @click="determineLink()">
      <div class="image" :class="item.imageMode" :style="{ backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: `url(${getBackgroundImage(item.imageMode, item.image)})`}">
        <img class="main" :src="getImage(item.image)" loading="lazy" />
      </div>

      <div class="title">
        {{ item.title }}
      </div>

      <div class="tags">
        <ul>
          <li :class="tag" v-for="(tag, key) in item.tags" :key="key">
            {{ tag }}
          </li>
        </ul>
      </div>
    </div>

    <Modal v-if="canRenderModal()" :open="open" @close="open = false">
      <img class="modal-slotted-image" :src="getModalImage(item)" loading="lazy" />

      <template v-slot:text v-if="item.modal_text">
        <h3>{{ item.title }}</h3>

        <div v-html="item.modal_text"></div>
      </template>

      <div class="portfolio-sub-items" v-if="item.stack">
        <div
          class="item-wrapper"

          v-for="(stackItem) in item.stack"
          :key="stackItem.id"
        >
          <PortfolioItem :item="stackItem" :modal="stackItem.modal" :hideTags="true" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Modal from "@/components/Modal.vue";

interface PortfolioItemData {
  id: String;
  title: String;
  modal_text: String;
  link: String;
  image: String;
  modal: Boolean;
  modalImage: String | undefined;
  tags: Array<string>;
  stack: Array<any> | undefined;
  hideTags: boolean;
}

export default defineComponent({
  name: "PortfolioItem",
  props: {
    item: {
      type: Object as PropType<PortfolioItemData>,
      required: true,
    }
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    getImage: function (image: String) {
      return require("../assets/" + image);
    },

    getBackgroundImage: function (imageMode: String, image: String) {
      if (imageMode !== 'full') return;

      return this.getImage(image);
    },

    getModalImage: function (item: PortfolioItemData) {
      const image =
        item.modalImage !== undefined ? item.modalImage : item.image;

      return require("../assets/" + image);
    },

    canRenderModal() {
      return (this.item as PortfolioItemData).modal;
    },

    determineLink() {
      const itemLink = (this.item as PortfolioItemData).link;
      if (itemLink !== "") {
        window.open(itemLink as string);
      } else if (this.canRenderModal()) {
        this.open = true;
      }
    },
  },
  components: {
    Modal,
  },
});
</script>

<style lang="scss" scoped>
.modal-slotted-image {
  max-height: 100%;
  width: 100%;
  border-radius: 6px;
  display: block;
  border: 3px solid rgba(255,255,255,.8);
}

.portfolio-sub-items {
  display: flex;
  flex-wrap: wrap;
  gap: var(--item-spacing);
  width: 100%;
  background: var(--deep-black);
  margin-top: var(--item-spacing);
  padding: var(--item-spacing);
  border-radius: 6px;

  .item-wrapper {
    flex-basis: 23%;
  }
}

.modal h3 {
  margin-top: 0;
}

.item {
  display: block;
  transition: all 0.3s ease-out;
  top: 0;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.03) rotate(-1deg);
    top: -10px;
  }

  .image {
    position: relative;
    padding-bottom: 62.54%;
    height: 0;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 3.33px 3.33px rgba(0, 0, 0, 0.25);

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(314deg, #393939, #5c5c5cb7);
      padding: 5px;
    }
    
    &.full img {
        object-fit: contain;
        object-position: top;
    }
  }

  .title {
    font-weight: 700;
    color: var(--white);
    margin: 8px 0px 0px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      &.design {
        color: var(--baby-blue);
      }
      &.development {
        color: var(--logo-pink);
      }
      &.fotografie {
        color: var(--yellow);
      }
      &.dronefotografie {
        color: var(--logo-orange);
      }

      + li {
        &:before {
          content: "&";
          color: var(--white);
          margin: 0px 7px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .portfolio-sub-items {
    .item-wrapper {
      flex-basis: 100%;
    }
  }
}
</style>