<template>
  <div class="modal-container" :class="{ open: open }">
    <div class="modal">
      <span class="close" @click="$emit('close')">&times; sluit</span>

      <slot></slot>

      <div class="text">
        <slot name="text"></slot>
      </div>
    </div>

    <div class="modal-bg" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    open: Boolean,
    content: String,
  },
  watch: {
    open(bool) {
      document.body.classList.toggle("modal-open");

      setTimeout(() => {
        if (!bool) {
          document
            .querySelectorAll(".item-wrapper.modal-open")[0]
            .classList.remove("modal-open");
        }

        const activeModel = document.querySelector(".modal-container.open");
        activeModel.closest(".item-wrapper").classList.add("modal-open");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;

  transition: all 0.15s ease-out;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.modal-bg {
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  max-width: 1400px;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    padding: 5px 10px;
    // width: 40px;
    height: 40px;
    border-radius: 6px;
    background: salmon;
    color: var(--white);
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1em;

    &:hover {
      color: var(--deep-black);
      background: var(--white);
      border: 2px solid var(--deep-black);
    }
  }
  
  .text:not(:empty) {
    margin-top: 30px;
    color: var(--white);
    background: var(--deep-black);
    padding: var(--item-spacing);
    border-radius: 6px;;
  }
}

@media (max-height: 99999px) {
  .modal {
    height: 95vh;
  }
}

@media (max-width: 1200px) {
  .modal {
    max-width: 90vw;
  }
}

@media (max-width: 768px) {
  .modal-container {
    padding: 10px;
  }

  .modal {
    padding: 10px;
    max-height: 95vh;
  }
}
</style>

<style lang="scss">
.item-wrapper.modal-open {
  z-index: 1;
}
</style>